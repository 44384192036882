<template>
  <v-dialog v-if="history" v-model="show" max-width="550" persistent>
    <v-card class="cardStyle">
      <v-card-title class="titleStyle">
        <span>Histórico da Mensagem</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-divider class="ma-0"></v-divider>

      <v-card-text id="cardScroll2" class="bodyStyle">
        <v-card-text v-for="h in history" v-bind:key="h.id">
          <div class="row h-row mb-0 rounded">
            <div class="col-7 pl-0">
              <v-subheader style="max-height: 24px">Alteração realizada por</v-subheader>
              <v-subheader style="max-height: 24px">
                <strong>{{ h.author }}</strong>
              </v-subheader>
            </div>
            <div class="col-5 pb-0">
              <v-subheader class="mt-0 h-line d-flex justify-end" style="max-height: 24px">Data/hora</v-subheader>
              <v-subheader class="d-flex justify-end" style="max-height: 24px">
                <strong>{{ h.time | datetime }}</strong>
              </v-subheader>
            </div>
            <div class="col-12 pt-0">
              <hr class="mt-0 h-line" />
              <div v-if="h.action === 'MESSAGE_CONDITION'">
                <v-subheader style="max-height: 24px" class="pl-0">
                  Urgente: <strong>&nbsp; {{ isUrgent(h.details) === 'Urgente' ? 'Sim' : 'Não' }}</strong>
                </v-subheader>
                <v-subheader style="max-height: 24px" class="pl-0">
                  Situação: <strong>&nbsp; {{ capitalize(h.details) }}</strong>
                </v-subheader>
              </div>
              <v-subheader v-if="h.action === 'MESSAGE_READ'" style="max-height: 24px" class="pl-0">
                Leitura de e-mail: <strong>&nbsp; Lido </strong>
              </v-subheader>
              <v-subheader v-if="h.action === 'COMMENT_ADDED'" style="max-height: 24px" class="pl-0">
                Novo comentario: <strong>&nbsp; {{ capitalize2(h.details) }}</strong>
              </v-subheader>
              <v-subheader v-if="h.action === 'MESSAGE_LOADED'" style="max-height: 24px" class="pl-0">
                Importação do e-CAC
              </v-subheader>
              <v-subheader v-if="h.action === 'EMAIL_SENDED'" style="max-height: 24px" class="pl-0">
                Email enviado
              </v-subheader>
            </div>
          </div>
        </v-card-text>

        <v-card-text class="h-title h-footer pa-4">
          <strong>Você visualizou todo histórico desta mensagem.</strong>
        </v-card-text>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-btn id="idBtnExport" class="btn-ag-secondary" outlined @click="exportar()">
          <v-icon>mdi-download-outline</v-icon>Exportar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn id="idBtnClose" class="btn-ag-primary" @click="show = false"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import IntegraContadorService from '@/services/integraContadorService.js';
import { eventBus } from '../../../../main';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalHistoryMessage',
  props: ['history'],

  data() {
    return {
      show: false,
      user: {
        name: 'Rodrigo Celso',
        urlAvatar: '/img/avatar.a5e81f19.png',
        color: 'red',
        icon: 'mdi-account-multiple',
      },
    };
  },

  computed: {
    itemHistoric() {
      return this.item;
    },

    ...mapGetters({
      userThumb: 'user/userInfo',
    }),
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },

    open() {
      this.show = true;
    },

    async exportar() {
      let item = {
        isn: this.history[0].isn,
        cnpj: this.history[0].cnpj,
      };
      let response = await IntegraContadorService.historic.downloadReport(item);
      if (response) this.loading3 = false;
      let blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let date = new Date();

      link.download = `Histórico de Mensagens do Ecac - Extraído em ${date.toLocaleDateString('pt-br')}.xlsx`;
      link.click();
      this.showToast('Download iniciado. Verifique o arquivo em seu computador.', 'success', 78);
      this.close();
    },
    // async getThumbnail(idUser) {
    //   let thumbnail = '';

    //   try {
    //     console.log('entrou no if');
    //     if (idUser) {
    //       const { response } = await gedService.profile.get(idUser);
    //       thumbnail = 'data:image/png;base64,' + response.data.base64;
    //     } else {
    //       thumbnail = require('@/assets/avatar.png');
    //     }
    //   } catch (error) {
    //     console.log('Erro ao buscar imagem do usuário: ', error);
    //   }
    //   return thumbnail;
    // },

    capitalize2(str) {
      str = str.split(',');

      let newString = str[0]
        .replaceAll('"', '')
        .replaceAll(']', '')
        .replaceAll('_', ' ')
        .toLowerCase()
        .replaceAll('[', '');

      return newString;
    },

    capitalize(str) {
      str = str.split(',');
      let newString = str[1]
        .replaceAll('"', '')
        .replaceAll(']', '')
        .replaceAll('_', ' ')
        .toLowerCase()
        .replaceAll('[', '');

      return newString[0].toUpperCase() + newString.slice(1);
    },

    isUrgent(str) {
      let array = str.split(',');
      let newString = array[0].replaceAll('"', '').replaceAll('[', '');

      return newString;
    },

    close() {
      this.show = false;
    },

    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },
  },
};
</script>
<style>
.bg-sub-title {
  background-color: #f9fafb;
}

.content-empty {
  background-color: #e7e7fa;
  color: #2a1c4f;
}
#cardScroll2 {
  flex-grow: 1;
  overflow: auto;
}

.content-empty {
  background-color: #fff;
}
.h-title {
  background-color: #e7e7fa;
}
.h-row {
  background-color: #fff;
}
.h-line {
  color: rgba(0, 0, 0, 0.6);
}
.h-footer {
  text-align: center;
  color: #2a1c4f !important;
}
hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
#export-btn {
  height: 48px !important;
  top: 0px;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 2px solid primary !important;
  background-color: #fff !important;
  color: var(--v-primary-base);
}
#export-btn :hover {
  background-color: #e3ffee !important;
  width: 150%;
  height: 250%;
  border-radius: 3px;
}
#close-btn :hover {
  background-color: #e3ffee !important;
  border-radius: 3px;
  width: 170% !important;
  height: 200% !important;
  color: var(--v-primary-base);
}

.titleStyle {
  font-size: 20px;
  padding: 16px !important;
  font-weight: 700 !important;
  align-content: center;
}

.bodyStyle {
  height: 420px !important;
  max-height: 420px !important;
  padding: 16px !important;
  background-color: #e7e7fa;
}

.cardStyle {
  padding: 0;
  height: 550px;
  max-height: 550px;
  display: flex;
  flex-direction: column;
}
.v-dialog {
  border: 0px solid var(--v-secondary-lighten5) !important;
}
</style>
