var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.history
    ? _c(
        "v-dialog",
        {
          attrs: { "max-width": "550", persistent: "" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "cardStyle" },
            [
              _c(
                "v-card-title",
                { staticClass: "titleStyle" },
                [
                  _c("span", [_vm._v("Histórico da Mensagem")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "ma-0" }),
              _c(
                "v-card-text",
                { staticClass: "bodyStyle", attrs: { id: "cardScroll2" } },
                [
                  _vm._l(_vm.history, function (h) {
                    return _c("v-card-text", { key: h.id }, [
                      _c("div", { staticClass: "row h-row mb-0 rounded" }, [
                        _c(
                          "div",
                          { staticClass: "col-7 pl-0" },
                          [
                            _c(
                              "v-subheader",
                              { staticStyle: { "max-height": "24px" } },
                              [_vm._v("Alteração realizada por")]
                            ),
                            _c(
                              "v-subheader",
                              { staticStyle: { "max-height": "24px" } },
                              [_c("strong", [_vm._v(_vm._s(h.author))])]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-5 pb-0" },
                          [
                            _c(
                              "v-subheader",
                              {
                                staticClass: "mt-0 h-line d-flex justify-end",
                                staticStyle: { "max-height": "24px" },
                              },
                              [_vm._v("Data/hora")]
                            ),
                            _c(
                              "v-subheader",
                              {
                                staticClass: "d-flex justify-end",
                                staticStyle: { "max-height": "24px" },
                              },
                              [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm._f("datetime")(h.time))),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-12 pt-0" },
                          [
                            _c("hr", { staticClass: "mt-0 h-line" }),
                            h.action === "MESSAGE_CONDITION"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-subheader",
                                      {
                                        staticClass: "pl-0",
                                        staticStyle: { "max-height": "24px" },
                                      },
                                      [
                                        _vm._v(" Urgente: "),
                                        _c("strong", [
                                          _vm._v(
                                            "  " +
                                              _vm._s(
                                                _vm.isUrgent(h.details) ===
                                                  "Urgente"
                                                  ? "Sim"
                                                  : "Não"
                                              )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "v-subheader",
                                      {
                                        staticClass: "pl-0",
                                        staticStyle: { "max-height": "24px" },
                                      },
                                      [
                                        _vm._v(" Situação: "),
                                        _c("strong", [
                                          _vm._v(
                                            "  " +
                                              _vm._s(_vm.capitalize(h.details))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            h.action === "MESSAGE_READ"
                              ? _c(
                                  "v-subheader",
                                  {
                                    staticClass: "pl-0",
                                    staticStyle: { "max-height": "24px" },
                                  },
                                  [
                                    _vm._v(" Leitura de e-mail: "),
                                    _c("strong", [_vm._v("  Lido ")]),
                                  ]
                                )
                              : _vm._e(),
                            h.action === "COMMENT_ADDED"
                              ? _c(
                                  "v-subheader",
                                  {
                                    staticClass: "pl-0",
                                    staticStyle: { "max-height": "24px" },
                                  },
                                  [
                                    _vm._v(" Novo comentario: "),
                                    _c("strong", [
                                      _vm._v(
                                        "  " +
                                          _vm._s(_vm.capitalize2(h.details))
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            h.action === "MESSAGE_LOADED"
                              ? _c(
                                  "v-subheader",
                                  {
                                    staticClass: "pl-0",
                                    staticStyle: { "max-height": "24px" },
                                  },
                                  [_vm._v(" Importação do e-CAC ")]
                                )
                              : _vm._e(),
                            h.action === "EMAIL_SENDED"
                              ? _c(
                                  "v-subheader",
                                  {
                                    staticClass: "pl-0",
                                    staticStyle: { "max-height": "24px" },
                                  },
                                  [_vm._v(" Email enviado ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ])
                  }),
                  _c("v-card-text", { staticClass: "h-title h-footer pa-4" }, [
                    _c("strong", [
                      _vm._v("Você visualizou todo histórico desta mensagem."),
                    ]),
                  ]),
                ],
                2
              ),
              _c(
                "v-card-actions",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-ag-secondary",
                      attrs: { id: "idBtnExport", outlined: "" },
                      on: {
                        click: function ($event) {
                          return _vm.exportar()
                        },
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-download-outline")]),
                      _vm._v("Exportar "),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-ag-primary",
                      attrs: { id: "idBtnClose" },
                      on: {
                        click: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    [_vm._v(" Fechar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }